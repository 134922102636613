<template>
  <v-container fluid class="top-banner-wrap">
    <v-img
      :src="require('../assets/best-business-broker.jpg')"
      class="image-css"
      contain
    />
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="down-menu">
            <ul>
              <!-- <li><router-link to="" class="hover_css">Employee View</router-link></li>
                  <li><router-link to="/Foreman" class="hover_css">Foreman View</router-link></li>
                  <li><router-link to="/Evaluation" class="hover_css">Evaluator View</router-link></li> -->
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Main_data",
  created() {
    this.checkPermissions();    

  },  
  data: () => ({
    //
    isDIY: localStorage.getItem('role')==7, 
    currentUserID: localStorage.getItem("userid"), 
  }),
  methods: {
    checkPermissions() {
      console.log('UserID:',this.currentUserID)
      if(this.isDIY){
        this.$router.push(`/DIYWelcome/${this.currentUserID}`);
      }       
    },
  } 
};
</script>

<style type="text/css">
.down-menu ul {
  padding: 0 51px 0 0;
  margin: 0;
  text-align: right;
  list-style: none;
}

.down-menu ul li {
  display: inline-block;
}

.down-menu a {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #989b9f !important;
}

.down-menu li + li {
  margin-left: 70px;
}

.down-menu {
  padding: 30px 0;
}

.top-banner-wrap .v-image__image {
  background-size: contain;
}

.hover_css:hover {
  color: #0091ff !important;
  text-decoration: underline;
}

@media only screen and (max-width: 960px) {
  .top-banner-wrap .v-image__image {
    background-size: contain;
  }

  .down-menu {
    padding: 10px 0;
  }

  .down-menu li + li {
    margin: 0 0 0 20px;
  }

  .down-menu ul {
    padding: 0;
  }
}

@media only screen and (max-width: 599px) {
  .down-menu a {
    font-size: 15px;
  }
}
</style>
